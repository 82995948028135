import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="p-4 bg-gray-800 text-white text-center">
      <p>&copy; {new Date().getFullYear()} YouNedia | Digital Marketing Agency. All rights reserved.</p>
      <p className="mb-4">
        YouNedia Marketing Agency is a premier, full-service digital marketing, web design, and consulting agency that generates explosive growth and revenue in businesses through creative strategy and optimization.
      </p>
      <div className="mt-4">
        <a href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
