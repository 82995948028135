import React, { useState } from 'react';
import { auth } from '../utils/FirebaseConfig'; // Import the Firebase auth
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // For navigation

const Auth = ({ onAuthSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(true); // Toggle between Login/Signup
  const navigate = useNavigate(); // Use React Router's useNavigate for redirecting

  // Handle signup or login based on the form type
  const handleAuth = async (e) => {
    e.preventDefault();
    try {
      if (isSignUp) {
        // Signup with Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        alert('Signup successful');
        onAuthSuccess(userCredential.user); // Pass the user data back to parent
        navigate('/youtube-link'); // Navigate to YouTube link page
      } else {
        // Login with Firebase Auth
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        alert('Login successful');
        onAuthSuccess(userCredential.user); // Pass the user data back to parent
        navigate('/youtube-link'); // Navigate to YouTube link page
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      alert('Google Sign-In successful');
      onAuthSuccess(user); // Pass the user data back to parent
      navigate('/youtube-link'); // Navigate to YouTube link page
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <h1 className="text-4xl font-bold mb-4">Welcome to YouNedia</h1>
        <p className="text-lg mb-6">PROMOTE YOUR VIDEO TO MILLIONS</p>
        <p className="text-lg mb-6">Get more YouTube views on your videos. Sign up now and get seen.</p>
     
      <form onSubmit={handleAuth} className="bg-white p-6 rounded shadow-md">
        <h1 className="text-lg mb-4">{isSignUp ? 'Sign Up' : 'Login'}</h1>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block border p-2 w-full mb-2"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="block border p-2 w-full mb-2"
        />
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded w-full">
          {isSignUp ? 'Sign Up' : 'Login'}
        </button>
        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="bg-white-500 text-white py-2 px-4 rounded w-full mt-4 flex items-center justify-center"
        >
       
          <img src={require("./google.png")} width="150" alt="Google"  />
        </button>
        <p className="mt-4 text-blue-500 cursor-pointer" onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? 'Already have an account? Login' : 'Don’t have an account? Sign Up'}
        </p>
    
      </form>
      <p className="text-center  max-w-">Here’s how it works: Sign up, paste your YouTube link, set your budget, and we’ll handle the rest. Your video will be promoted to your target audience, helping you gain more visibility and engagement.</p>

    </div>
  );
};

export default Auth;
