import React, { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../utils/FirebaseConfig";

const CombinedForm = ({ user }) => {
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [urlError, setUrlError] = useState(false);
  const [country, setCountry] = useState("");
  const [budget, setBudget] = useState("");
  const [isForeign, setIsForeign] = useState(false);
  const [duration, setDuration] = useState("");
  const [views, setViews] = useState(0);

  // List of countries for the dropdown
  const countries = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "India",
    "Germany",
    "France",
    "Spain",
    "Italy",
    "Brazil",
    "Mexico",
    "Japan",
    "South Korea",
    "China",
    "Russia",
    "South Africa",
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => console.log("Razorpay script loaded");
    script.onerror = () => console.error("Failed to load Razorpay script");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    let baseViews = (parseFloat(budget) / 100) * 5000; // 100 dollars = 5000 views
    let newViews = baseViews || 0; // Default to 0 if baseViews is NaN

    // Increase views for longer duration (10 to 20 minutes)
    if (duration === "10 to 20") {
      newViews *= 0.5; // Increase views by 20% for longer videos
    }
    // Decrease views for shorter duration (6 to 10 minutes)
    else if (duration === "6 to 10") {
      newViews *= 0.8; // Decrease views by 20% for shorter videos
    }
    // Decrease views for foreign countries
    if (isForeign) {
      newViews *= 0.8; // Decrease views by 20% for foreign countries
    } else if (country === "India" && duration === "0 to 3") {
      newViews *= 1.3; // Increase views by 30% for short duration in India
    }
    setViews(newViews);
  }, [budget, isForeign, duration, country]);

  const extractVideoId = (url) => {
    const standardUrlMatch = url.match(/v=([a-zA-Z0-9_-]{11})/);
    const shortUrlMatch = url.match(/youtu.be\/([a-zA-Z0-9_-]{11})/);
    const embedUrlMatch = url.match(/embed\/([a-zA-Z0-9_-]{11})/);
    const shortsUrlMatch = url.match(/shorts\/([a-zA-Z0-9_-]{11})/);

    if (standardUrlMatch) return standardUrlMatch[1];
    if (shortUrlMatch) return shortUrlMatch[1];
    if (embedUrlMatch) return embedUrlMatch[1];
    if (shortsUrlMatch) return shortsUrlMatch[1];
    return "";
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setYoutubeUrl(url);
    const videoId = extractVideoId(url);

    if (videoId) {
      setThumbnailUrl(`https://img.youtube.com/vi/${videoId}/0.jpg`);
      setUrlError(false);
    } else {
      setThumbnailUrl("");
      setUrlError(true);
    }
  };

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setCountry(value);
    setIsForeign(value !== "India"); // Assuming 'India' is your local country
  };

  const handlePayment = () => {
    if (typeof window.Razorpay === "undefined") {
      alert(
        "Razorpay SDK is not loaded. Please check your internet connection."
      );
      return;
    }
    const usdToInrConversionRate = 80; // Example conversion rate
    const budgetInINR = parseFloat(budget) * usdToInrConversionRate * 100; // Convert to paisa
    const options = {
      key: "rzp_live_O9DAoCvmp5KtL1", // Replace with your Razorpay test key ID
      amount: budgetInINR,
      currency: "INR",
      name: "Video Promotion Payment",
      description: "Promotion Payment",
      handler: function (response) {
        console.log("Payment successful:", response);
        const formData = {
          youtubeUrl,
          targetCountry: country,
          views,
          duration,
          isForeign,
          budgetInUSD: budget,
          budgetInINR: budgetInINR / 100,
        };
        savePaymentToFirestore(formData, response.razorpay_payment_id);
      },
      prefill: {
        name: user?.displayName || "Guest",
        email: user?.email || "guest@example.com",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const savePaymentToFirestore = async (formData, paymentId) => {
    try {
      // Add a new document to the 'payments' collection
      const docRef = await addDoc(collection(db, 'payments'), {
        ...formData,
        paymentId,
        timestamp: new Date(),
        userName: user?.displayName || "Guest",  // Adding user name
        userEmail: user?.email || "guest@example.com",  // Adding user email
      });
      console.log("Payment details successfully saved to Firebase", docRef.id);
      alert("Payment details successfully saved!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("youtubeUrl:", youtubeUrl);
    console.log("duration:", duration);
    console.log("country:", country);
    console.log("budget:", budget);

    if (!youtubeUrl || !duration || !country || !budget) {
      alert("Please fill out all required fields.");
      return;
    }
    try {
      await handlePayment();
    } catch (error) {
      console.error("Payment handling error:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h1 className="text-2xl font-bold text-center mb-6">
          Paste YouTube Link
        </h1>
        <input
          type="text"
          value={youtubeUrl}
          onChange={handleUrlChange}
          placeholder="YouTube Link"
          className="w-full p-3 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {urlError ? (
          <p className="text-red-500 text-sm text-center mb-4">
            Invalid YouTube link. Please check the URL.
          </p>
        ) : thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            alt="YouTube Thumbnail"
            className="w-full h-auto rounded-md mb-4"
          />
        ) : null}

        {youtubeUrl && !urlError ? (
          user ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <h4 className="text-lg font-semibold">
                  Wants and Target Audience
                </h4>
                <div>
                  <h5 className="font-semibold mb-2">Duration:</h5>
                  <div className="flex flex-col space-y-2 mb-4">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="duration"
                        value="0 to 3"
                        checked={duration === "0 to 3"}
                        onChange={() => setDuration("0 to 3")}
                      />
                      <span>0-3 min</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="duration"
                        value="3 to 6"
                        checked={duration === "3 to 6"}
                        onChange={() => setDuration("3 to 6")}
                      />
                      <span>3-6 min</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="duration"
                        value="6 to 10"
                        checked={duration === "6 to 10"}
                        onChange={() => setDuration("6 to 10")}
                      />
                      <span>6-10 min</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="duration"
                        value="10 to 20"
                        checked={duration === "10 to 20"}
                        onChange={() => setDuration("10 to 20")}
                      />
                      <span>10-20+ min</span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="font-semibold mb-2">Target Country:</h5>
                <select
                  value={country}
                  onChange={handleLocationChange}
                  className="w-full p-3 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a country</option>
                  {countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <h5 className="font-semibold mb-2">Budget $:</h5>
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      placeholder="Enter Budget"
                      className="w-full pl-8 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
              </div>
              <p className="font-semibold mb-2">Estimated Views: {views}</p>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition-colors"
              >
                Proceed to Payment
              </button>
            </form>
          ) : (
            <div className="text-center">
              <p>Please log in to proceed.</p>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};
export default CombinedForm;
