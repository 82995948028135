import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";  // Import Firestore
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBt211LmAlCxDUFajJ23y1vxv6QPqDmNDg",
  authDomain: "promotion-data-86210.firebaseapp.com",
  projectId: "promotion-data-86210",
  storageBucket: "promotion-data-86210.appspot.com",
  messagingSenderId: "722125016001",
  appId: "1:722125016001:web:176bd82c4f88b8118d3d40",
  measurementId: "G-VY52XTEQE4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore (database)
const analytics = getAnalytics(app);

export { app, auth, db, analytics };  // Export Firestore as well
